import './appointment-stripe-page.scss';

const AppStripePage = () => {
    return (
        <div className="appointment-stripe-page-container">
            <div className="appointment-stripe-page-title-container">
                <h2 className="appointment-stripe-page-title">Connect Stripe Page here...</h2>
            </div>
        </div>
    )
}

export default AppStripePage;